"use client";

import Link from "next/link";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Logo } from "@/components/Logo/Logo";
import { Button } from "@/components/shadcn/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/shadcn/form";
import { Input } from "@/components/shadcn/input";
import { signIn } from "@/server/actions/users";

const formSchema = z.object({
  email: z.string(),
});

export default function SignInForm() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      await signIn(data.email);
    } catch {
      form.setError("email", {
        message: "User not found",
      });
    }
  };

  return (
    <Card className="mx-auto w-full max-w-md">
      <CardHeader className="flex items-center justify-center">
        <Link href="#" prefetch={false}>
          <Logo size={50} />
        </Link>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2 text-center">
          <CardTitle className="text-2xl font-bold">
            Sign in to your account
          </CardTitle>
          <CardDescription>
            Enter your email, and we&apos;ll send you a one-time password (OTP)
            along with a magic link for quick and secure sign-in.
          </CardDescription>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full"
              disabled={form.formState.isSubmitting}
            >
              Send OTP + Magic Link
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
