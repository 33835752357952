"use client";

import React from "react";

import Image from "next/image";

const LogoLight = ({
  size,
  className,
}: {
  size: number;
  className: string;
}) => {
  return (
    <Image
      src="/purplefish-symbol-light.svg"
      alt="Purple Fish Logo"
      width={size}
      height={size}
      priority
      className={className}
    />
  );
};

const LogoDark = ({ size, className }: { size: number; className: string }) => {
  return (
    <Image
      src="/purplefish-symbol-dark.svg"
      alt="Purple Fish Logo"
      width={size}
      height={size}
      priority
      className={className}
    />
  );
};

interface LogoProps {
  size?: number;
}

export const Logo = ({ size = 40 }: LogoProps) => {
  return (
    <>
      <LogoLight size={size} className="block dark:hidden" />
      <LogoDark size={size} className="hidden dark:block" />
    </>
  );
};
